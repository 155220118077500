import AppConsts from './appconst.js';
import Util from './util.js';

const myVueFilter = {
	install(Vue,options){
		
		Vue.filter('absUrl',function (value) {
			if(value)
			{
				let lowerCaseValue = value.toLowerCase();
				if (lowerCaseValue.toLowerCase().indexOf('http://') >=0 || lowerCaseValue.indexOf('https://')>=0) {
					return value;
				}
				return AppConsts.remoteServiceBaseUrl + value;
			} else {
				return '/static/nopic.png';
			}
		});
		
		
		Vue.filter('formatDate',function (value,format) {
			if (value) {
				return Util.formatDate(value,format);
			} else {
				return '';
			}
		})
	}
};

export default myVueFilter;