import ajax from '../../lib/ajax'
export default {
	namespaced: true,
	state: {
		loading: false,
		cartData: {
			cartTotal: {
				goodsCount: 0,
				checkedGoodsCount: -1,
				goodsAmount: 0,
				checkedGoodsAmount: 0
			},
			isMultiOrderModel: 0,
			cartList: []
		}
	},
	mutations: {
		setLoading(state, status) {
			state.loading = status;
		},
		setCartData(state, data) {
			state.cartData = data;
		},
		reset(state) {
			state.cartData = {
				cartTotal: {
					goodsCount: 0,
					checkedGoodsCount: -1,
					goodsAmount: 0,
					checkedGoodsAmount: 0
				},
				isMultiOrderModel: 0,
				cartList: []
			};
		},
		setGoodsCount(state, data) {
			state.cartData.cartTotal.goodsCount = data;
		}
	},
	actions: {
		// 添加购物车
		add({
			commit,
			dispatch
		}, products) {
			commit('setLoading', true);
			ajax.post('/mall/app/cart/add', products)
				.then(res => {
					let countInCart = res.data.data;
					//console.log('添加购物车：', countInCart);
					commit('setLoading', false);
					return dispatch('detail')
				});
		},
		//立即购买
		fastAdd({
			commit,
			dispatch
		}, postData) {
			commit('setLoading', true);
			ajax.post('/mall/app/cart/fastadd', postData)
				.then(res => {
					// console.log('立即购买：', res.data.data);
					commit('setLoading', false);
					return dispatch('detail')
				});
		},
		// 获取购物车详情
		detail({
			commit
		}) {
			commit('setLoading', true);
			return ajax.get('/mall/app/cart/index')
				.then(res => {
					// console.log('购物车详情', res.data.data);
					commit('setCartData', res.data.data);
					commit('setLoading', false);
				})
		},
		// 删除购物车商品
		delete({
			commit
		}, productIds) {
			commit('setLoading', true);
			return ajax.post('/mall/app/cart/delete', productIds)
				.then(res => {
					// console.log('删除商品返回数据：', res.data.data);
					commit('setLoading', false);
					commit('setCartData', res.data.data);
					
				});
		},
		// 获取购物车商品数量
		getGoodsCount({
			commit
		}) {
			commit('setLoading', true);
			return ajax.get('/mall/app/cart/goodscount')
				.then(res => {
					// console.log('商品数量：', res.data.data);
					commit('setGoodsCount', res.data.data);
					commit('setLoading', false);
				})
		},
		// 更新购物车商品数量
		updateGoodsCount({
			commit,
			dispatch
		}, postData) {
			// context.commit('setLoading', true);
			commit('setLoading', true);
			ajax.post('/mall/app/cart/update', postData)
				.then(res => {
					// context.commit('setLoading', false);
					commit('setLoading', false)
					if (res && res.data && res.data.code == 0) {
						// context.dispatch('detail')
						return dispatch('detail')
					}
				});
		},
		checkGoods({
			commit
		}, postData) {
			commit('setLoading', true);
			return ajax.post('/mall/app/cart/checked', postData)
				.then(res => {
					// console.log('勾选商品返回数据：', res.data.data);
					if (res && res.data && res.data.code == 0) {
						commit('setCartData', res.data.data);
					}
					commit('setLoading', false);
				});
		}
	}
}
