import Vue from "vue";
import VueRouter from "vue-router";
import ajax from "../lib/ajax.js";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home/index",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home/Home.vue"),
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("../views/home/Index.vue"),
      },
      {
        path: "system-article/:id?",
        name: "system-article",
        component: () => import("../views/home/system-article.vue"),
      },
      {
        path: "company-profile",
        name: "company-profile",
        component: () => import("../views/home/company-profile.vue"),
      },
      {
        path: "company-honor",
        name: "company-honor",
        component: () => import("../views/home/company-honor.vue"),
      },
    ],
  },
  {
    path: "/my",
    name: "my",
    component: () => import("../views/my/my.vue"),
    children: [
      {
        path: "infomation",
        name: "infomation",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/infomation.vue"),
      },
      {
        path: "updateavatar",
        name: "updateavatar",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/updateavatar.vue"),
      },
      {
        path: "updatephone",
        name: "updatephone",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/updatephone.vue"),
      },
      {
        path: "updatepwd",
        name: "updatepwd",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/updatePwd.vue"),
      },
      {
        path: "updateCertificate/:index",
        name: "updateCertificate",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/updateCertificate.vue"),
      },
      {
        path: "bandCard",
        name: "bandCard",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/bandCard.vue"),
      },
      {
        path: "addBandCard",
        name: "addBandCard",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/addBandCard.vue"),
      },
      {
        path: "receivingAddress",
        name: "receivingAddress",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/receivingAddress.vue"),
      },
      {
        path: "contactService",
        name: "contactService",
        component: () => import("../views/my/contactService.vue"),
      },
      {
        path: "mypoints",
        name: "mypoints",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/mypoints.vue"),
      },
      {
        path: "pointCash",
        name: "pointCash",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/pointCash.vue"),
      },
      {
        path: "point-detail/:item",
        name: "point-detail",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/point-detail.vue"),
      },
      {
        path: "mySands",
        name: "mySands",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/mySands.vue"),
      },
      {
        path: "sands-detail/:item",
        name: "sands-detail",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/sands-detail.vue"),
      },
      {
        path: "wait-Sh/:item",
        name: "wait-Sh",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/wait-Sh.vue"),
      },
      {
        path: "cash-Out",
        name: "cash-Out",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/cash-Out.vue"),
      },
      {
        path: "security",
        name: "security",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/securitySetting.vue"),
      },
      {
        path: "myCard",
        name: "myCard",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/myCard.vue"),
      },
      {
        path: "createBusinessCards",
        name: "CreateBusinessCards",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/createBusinessCards.vue"),
      },
      {
        path: "myQRCode",
        name: "myQRCode",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/myQRCode.vue"),
      },
      {
        path: "myTeam",
        name: "myTeam",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/myTeam.vue"),
      },
      {
        path: "myTeamDetail/:item",
        name: "myTeamDetail",
        meta: {
          requiresAuth: true,
        },
        component: () => import("../views/my/myTeamDetail.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/bindphone/:item?",
    name: "bindphone",
    component: () => import("../views/login/bindPhone.vue"),
  },
  {
    path: "/register/:index?",
    name: "register",
    component: () => import("../views/login/physicianRegister.vue"),
  },
  {
    path: "/material",
    name: "material",
    component: () => import("../views/login/perfectMaterial.vue"),
  },
  {
    path: "/forgetpwd",
    name: "forgetpwd",
    component: () => import("../views/login/forgetPwd.vue"),
  },
  {
    path: "/uploadPic",
    name: "uploadPic",
    component: () => import("../views/login/uploadPic.vue"),
  },
  {
    path: "/club",
    name: "club",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/club/club.vue"),
    children: [
      {
        path: "memberClub",
        name: "memberClub",
        component: () => import("../views/club/memberClub.vue"),
      },
      {
        path: "forum",
        name: "forum",
        component: () => import("../views/club/forum.vue"),
      },
      {
        path: "myPublication/:id",
        name: "myPublication",
        component: () => import("../views/club/myPublication.vue"),
      },
      {
        path: "publish/:parameter",
        name: "publish",
        component: () => import("../views/club/publish.vue"),
      },
      {
        path: "learning",
        name: "learning",
        component: () => import("../views/club/learning.vue"),
      },
      {
        path: "salon",
        name: "salon",
        component: () => import("../views/club/salon.vue"),
      },
      {
        path: "grow",
        name: "grow",
        component: () => import("../views/club/grow.vue"),
      },
      {
        path: "grow-play",
        name: "grow-play",
        component: () => import("../views/club/grow-play.vue"),
      },
      {
        path: "article-details/:id/:name?",
        name: "article-details",
        component: () => import("../views/club/article-details.vue"),
      },
    ],
  },
  {
    path: "/voice",
    name: "voice",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/voice/voice.vue"),
    children: [
      {
        path: "shangyangVoice",
        name: "shangyangVoice",
        component: () => import("../views/voice/shangyangVoice.vue"),
      },
      {
        path: "direct-broadcast",
        name: "direct-broadcast",
        component: () => import("../views/voice/direct-broadcast.vue"),
      },
      {
        path: "welfare",
        name: "welfare",
        component: () => import("../views/voice/welfare.vue"),
      },
      {
        path: "fund",
        name: "fund",
        component: () => import("../views/voice/fund.vue"),
      },
    ],
  },
  {
    path: "/superior",
    name: "superior",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/superior/superior.vue"),
    children: [
      {
        path: "superior-products",
        name: "superior-products",
        component: () => import("../views/superior/superior-products.vue"),
      },
      {
        path: "youpin-products/:index",
        name: "youpin-products",
        component: () => import("../views/superior/youpin-products.vue"),
      },
      {
        path: "exchange-succeeded",
        name: "exchange-succeeded",
        component: () => import("../views/superior/exchange-succeeded.vue"),
      },
      {
        path: "youpin-detail/:id",
        name: "youpin-detail",
        component: () => import("../views/superior/youpin-detail.vue"),
      },
      {
        path: "place-order/:id/:number",
        name: "place-order",
        component: () => import("../views/superior/place-order.vue"),
      },
    ],
  },
  {
    path: "/footprint",
    name: "footprint",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/footprint/footprint.vue"),
    children: [
      {
        path: "myfootprint",
        name: "myfootprint",
        component: () => import("../views/footprint/myfootprint.vue"),
      },
    ],
  },
  {
    path: "/yunzhan",
    name: "yunzhan",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/yunzhan/yunzhan.vue"),
    children: [
      {
        path: "exhibit",
        name: "exhibit",
        component: () => import("../views/yunzhan/exhibit.vue"),
      },
      {
        path: "selectProduct",
        name: "selectProduct",
        component: () => import("../views/my/selectProduct.vue"),
      },
      {
        path: "exhibit-list",
        name: "exhibit-list",
        component: () => import("../views/yunzhan/exhibit-list.vue"),
      },
      {
        path: "exhibit-pro-detail/:id",
        name: "exhibit-pro-detail",
        component: () => import("../views/yunzhan/exhibit-pro-detail.vue"),
      },
      {
        path: "contact-us/:id",
        name: "contact-us",
        component: () => import("../views/yunzhan/contact-us.vue"),
      },
      {
        path: "exhibit-product/:id",
        name: "exhibit-product",
        component: () => import("../views/yunzhan/exhibit-product.vue"),
      },
      {
        path: "enterprise-index/:id",
        name: "enterprise-index",
        component: () => import("../views/yunzhan/enterprise-index.vue"),
      },
      {
        path: "enterprise-archives/:id",
        name: "enterprise-archives",
        component: () => import("../views/yunzhan/enterprise-archives.vue"),
      },
      {
        path: "famous-detail/:id",
        name: "famous-detail",
        component: () => import("../views/yunzhan/famous-detail.vue"),
      },
      {
        path: "famous-pro-detail/:id/:item",
        name: "famous-pro-detail",
        component: () => import("../views/yunzhan/famous-pro-detail.vue"),
      },
      {
        path: "strategic-window",
        name: "strategicWindow",
        component: () => import("../views/yunzhan/strategic-window.vue"),
      },
    ],
  },
  {
    path: "/favorites",
    name: "favorites",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/favorites/favorites.vue"),
    children: [
      {
        path: "collection/:tab",
        name: "collection",
        component: () => import("../views/favorites/collection.vue"),
      },
    ],
  },
  {
    path: "/message",
    name: "message",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/message/message.vue"),
    children: [
      {
        path: "messages",
        name: "messages",
        component: () => import("../views/message/messages.vue"),
      },
    ],
  },
  {
    path: "/shopping",
    name: "shopping",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/shopping/shopping.vue"),
    children: [
      {
        path: "shopping-cart",
        name: "shopping-cart",
        component: () => import("../views/shopping/shopping-cart.vue"),
      },
    ],
  },
  {
    path: "/products",
    name: "products",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/products/products.vue"),
    children: [
      {
        path: "pro-detail/:id",
        name: "pro-detail",
        component: () => import("../views/products/pro-detail.vue"),
      },
      {
        path: "pro-hot-list/:index",
        name: "pro-hot-list",
        component: () => import("../views/products/pro-hot-list.vue"),
      },
      {
        path: "submit-order",
        name: "submit-order",
        component: () => import("../views/products/submit-order.vue"),
      },
      {
        path: "preview-order",
        name: "preview-order",
        component: () => import("../views/products/preview-order.vue"),
      },
      {
        path: "payment",
        name: "payment",
        component: () => import("../views/products/payment.vue"),
      },
    ],
  },
  {
    path: "/product-list",
    name: "product-list",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/products/product-list.vue"),
  },
  {
    path: "/search/:keywords",
    name: "search",
    component: () => import("../views/products/search.vue"),
  },
  {
    path: "/order",
    name: "order",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/order/order.vue"),
    children: [
      {
        path: "my-order",
        name: "my-order",
        component: () => import("../views/order/my-order.vue"),
      },
      {
        path: "look-logistics",
        name: "look-logistics",
        component: () => import("../views/order/look-logistics.vue"),
      },
      {
        path: "order-detail",
        name: "order-detail",
        component: () => import("../views/order/order-detail.vue"),
      },
    ],
  },
  {
    path: "/problem",
    name: "problem",
    component: () => import("../views/problem/problem.vue"),
    children: [
      {
        path: "logistics-query",
        name: "logistics-query",
        component: () => import("../views/problem/logistics-query.vue"),
      },
      {
        path: "kefu",
        name: "kefu",
        component: () => import("../views/problem/kefu.vue"),
      },
      {
        path: "about-us",
        name: "about-us",
        component: () => import("../views/problem/about-us.vue"),
      },
      {
        path: "point-cash-how",
        name: "point-cash-how",
        component: () => import("../views/problem/point-cash-how.vue"),
      },
      {
        path: "place-order-how",
        name: "place-order-how",
        component: () => import("../views/problem/place-order-how.vue"),
      },
      {
        path: "password-security",
        name: "password-security",
        component: () => import("../views/problem/password-security.vue"),
      },
      {
        path: "cancel-condition",
        name: "cancel-condition",
        component: () => import("../views/problem/cancel-condition.vue"),
      },
      {
        path: "help-and-feedback",
        name: "help-and-feedback",
        component: () => import("../views/problem/help-and-feedback"),
      },
    ],
  },
  {
    path: "search-partner",
    name: "searchPartner",
    component: () => import("../views/login/searchPartner.vue"),
  },
  {
    path: "/projectDetail",
    name: "projectDetail",
    component: () => import("../views/projectModule/projectDetail.vue"),
  },
];

const router = new VueRouter({
  mode: process.env.NODE_ENV === "production" ? "history" : "hash",
  routes,
});
let vm = new Vue({});
router.beforeEach((to, from, next) => {
  if (to.name === "login") {
    let code = to.query.code || null;
    // 微信授权登陆
    if (code) {
      ajax
        .get("/mall/app/auth/weChatLogin", {
          params: {
            code: code,
            type: 2,
          },
        })
        .then((rep) => {
          if (rep && rep.data) {
            if (rep.data.code == 0) {
              // 登录成功
              let { userInfo, token } = rep.data.data;
              //console.log(userInfo)
              store.commit("session/login", {
                token,
                userInfo,
              });
              next("/");
              // 登录成功之后要执行的逻辑
              store.dispatch("cart/detail");
            } else if (rep.data.code == 700) {
              //未关联微信,提示关联
              // vm.$Message.error({
              //     content: "您还未关联微信，请到APP端进行关联!"
              // });
              next({
                name: "bindphone",
                params: {
                  unionId: rep.data.data.unionId,
                },
              });
            } else if (rep.data.code == 999) {
              //禁用
              vm.$Message.error({
                content: "你的账号已被禁用！",
              });
              next("/");
            } else {
              vm.$Message.error({
                content: "登录失败!",
              });
              next("/");
            }
          } else {
            vm.$Message.error({
              content: "登录失败!",
            });
            next("/");
          }
        });
    } else {
      next();
    }
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.state.session.hasLogin) {
        next({
          path: "/login",
        });
      } else if (
        store.state.session.userInfo.status == 0 ||
        store.state.session.userInfo.status == 4
      ) {
        // console.log(store.state.session.userInfo.status,"store.state.session.userInfo.status")
        if (to.name == "messages") {
          next();
        } else {
          next({
            path: "/register/1",
          });
        }
      } else if (
        (store.state.session.userInfo.status == 1 &&
          store.state.session.userInfo.partnertype !== 1) ||
        store.state.session.userInfo.status == 2
      ) {
        store.commit("session/logout");
        next({
          path: "/",
        });
      } else {
        // console.log(to, "to");
        if (
          store.state.session.userInfo.userType == 5 &&
          to.name != "exhibit" &&
          to.name != "infomation" &&
          to.name != "collection" &&
          to.name != "security" &&
          to.name != "updatepwd" &&
          to.name != "exhibit-list" &&
          to.name != "famous-detail" &&
          to.name != "enterprise-index" &&
          to.name != "exhibit-product" &&
          to.name != "enterprise-archives" &&
          to.name != "contact-us" &&
          to.name != "pro-hot-list" &&
          to.name != "famous-pro-detail" &&
          to.name != "pro-detail" &&
          to.name != "product-list" &&
          to.name != "article-details"
        ) {
          vm.$Modal.success({
            title: "提示",
            content: "您没有该权限!",
          });
          return;
        }
        next();
      }
    } else {
      next();
    }
  }
});
export default router;
