import axios from 'axios'
import appconst from './appconst'
import Vue from 'vue'
import util from './util.js'
import store from '../store'

const ajax = axios.create({
	baseURL: appconst.remoteServiceBaseUrl,
	timeout: 30000,
	retry: 1,
	retryDelay: 1000
})
ajax.interceptors.request.use(
	function(config) {
		if (window.abp.auth.getToken()) {
			config.headers.common['token'] = window.abp.auth.getToken()
		}
		//console.log('config.url',config.url);
		return config
	},
	function(error) {
		return Promise.reject(error)
	}
)
let vm = new Vue({})
ajax.interceptors.response.use(
	respon => {
		// 这一步可以直接返回respon.data，省去了每次获取数据都需要再进行.data
		return respon
	},
	error => {
		let config = error.config;
        
		if (error.response) {
			// 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
			if (error.response.data.code == 401) {
				util.abp.auth.clearToken();
				store.commit("session/setHasLogin", false);
				// 如果配置不存在或未设置重试选项，则拒绝
				if (!config || !config.retry) return Promise.reject(error);

				// 设置变量以跟踪重试次数
				config.__retryCount = config.__retryCount || 0;

				// 判断是否超过总重试次数
				if (config.__retryCount >= config.retry) {
					// 返回错误并退出自动重试
					return Promise.reject(error);
				}

				// 增加重试次数
				config.__retryCount += 1;
				//打印当前重试次数
				console.log(config.url + ' 自动重试第' + config.__retryCount + '次');
				// 创建新的Promise
				let backoff = new Promise(function(resolve) {
					setTimeout(function() {
						resolve();
					}, config.retryDelay || 1000);
				});

				// 返回重试请求
				return backoff.then(function() {
					return ajax(config);
				});
			}
			vm.$Modal.error({
				title: '错误提示',
				content: error.response.data.msg
			})
		} else if (error.request) {
			// 请求已经成功发起，但没有收到响应
			vm.$Modal.error({
				title: '错误提示',
				content: '服务器没有响应'
			})
		} else {
			vm.$Modal.error('未知错误')
		}
		setTimeout(() => {
			vm.$Message.destroy()
		}, 1000)
		return Promise.reject(error)
	}
)
export default ajax
