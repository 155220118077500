import Util from "@/lib/util.js";
import ajax from '../../lib/ajax'
import Vue from 'vue'
let vm = new Vue({});
export default {
	namespaced: true,
	state: {
		loading: false,
		orderList: [],
		showType: 0,
		userId: null,
		page: 1,
		size: 10,
		totalPages: 1,
		total: 0,
		orderCounts: {
			unrecv: 0,
			uncomment: 0,
			unpaid: 0,
			unship: 0
		},
		order: {
			"orderInfo": {
				"consignee": "",
				"address": "",
				"addTime": "",
				"orderSn": "",
				"actualPrice": 0,
				"mobile": "",
				"discountPrice": 0,
				"expCode": "",
				"orderStatusText": "",
				"goodsPrice": 0,
				"expNo": "",
				"id": 0,
				"isInvoice": false,
				"freightPrice": 0,
				"handleOption": {
					"cancel": false,
					"delete": false,
					"pay": false,
					"comment": false,
					"confirm": false,
					"refund": false,
					"rebuy": false
				}
			},
			"orderGoods": []
		}
	},
	mutations: {
		setLoading(state, status) {
			state.loading = status;
		},
		setOrderList(state, list) {
			state.orderList = list;
		},
		deleteOrderById(id) {
			let index = state.orderList.findIndex(o => o.id == id);
			if (index != -1) {
				state.orderList.splice(index, 1);
			}
		},
		setOrder(state, order) {
			state.order = order;
		},
		setOrderCounts(state, orderCounts) {
			state.orderCounts = orderCounts;
		},
		setShowType(state, showType) {
			state.showType = showType;
			state.page = 1;
			state.totalPages = 1;
			state.orderList = [];
		},
		setUserId(state, userId) {
			state.userId = userId;
		},
		setPage(state, page) {
			state.page = page;
		},
		setPageSize(state, size) {
			state.size = size;
		},
		setTotalPages(state, p) {
			state.totalPages = p;
		},
		setTotal(state, p) {
			state.total = p;
		}
	},
	actions: {
		// 获取订单列表
		getAll({
			commit,
			dispatch,
			state
		}, preload) {
			//console.log('getAll',state.userId);
			if (!state.userId) {
				return;
			}
			// if (state.page > state.totalPages) {
			// 	vm.$Message.error({
			// 		content: '获取订单列表失败！'
			// 	});
			// 	return Promise.reject();
			// }
			preload = preload || {
				userId: state.userId,
				showType: state.showType,
				page: state.page,
				size: state.size
			};
			commit('setLoading', true);
			return ajax.get('/mall/app/order/list', {
					params: preload
				})
				.then(async res => {
					if (res && res.data && res.data.code == 0) {
						// console.log('我的订单列表请求成功', res)
						let list = res.data.data.data;
						commit('setTotalPages', res.data.data.totalPages);
						commit('setTotal', res.data.data.count);
						// for (let order of list) {
						// 	if (order.orderStatus == 301) {
						// 		let rep = await ajax.get('/mall/app/order/expressTrace', {
						// 			params: {
						// 				orderId: order.id
						// 			}
						// 		});
						// 		//console.log('物流接口返回',rep.data.data);
						// 		if (rep && rep.data && rep.data.code == 0) {
						// 			order.express = rep.data.data;
						// 			if (rep.data.data.traces) {
						// 				let traces = JSON.parse(rep.data.data.traces);
						// 				if (traces.responseItems && traces.responseItems.length > 0) {
						// 					let responseItems = traces.responseItems;
						// 					order.lastShipDetail = responseItems[responseItems.length - 1]
						// 						.opDesc;
						// 				}
						// 			}
						// 		}
						// 	}
						// }
						//console.log(list);
						commit('setOrderList', list);
					} else {
						vm.$Message.error({
							content: '获取订单列表失败！'
						});
					}
					commit('setLoading', false);
				});
		},
		getOrderCounts({
			commit,
			dispatch
		}) {
			commit('setLoading', true);
			return ajax.get('/mall/app/order/orderInfo').then(res => {
				if (res && res.data && res.data.code == 0) {
					commit('setOrderCounts', res.data.data);
				}
				commit('setLoading', false);
			});
		},
		// 获取订单详情
		get({
			commit,
			dispatch
		}, preload) {
			commit('setLoading', true);
			return ajax.get('/mall/app/order/detail', {
					params: preload
				})
				.then(res => {
					if (res && res.data && res.data.code == 0) {
						commit('setOrder', res.data.data);
					} else {
						vm.$Message.error({
							content: '获取订单详情失败！'
						});
					}
					commit('setLoading', false);
				})
		},
		// 删除订单
		deleteOrder({
			commit,
			dispatch,
            state
		}, preload) {
			commit('setLoading', true);
			vm.$Modal.confirm({
				title: '删除订单',
				content: '确认删除订单吗?',
				onOk() {
                    let reducePage = state.orderList.length == 1;
					return ajax.post('/mall/app/order/delete', preload).then(p => {
						if (p && p.data && p.data.code == 0) {
                            if (reducePage) {
                                commit("setPage", state.page -1);
                            }
							dispatch('getAll');
							dispatch('getOrderCounts');
							vm.$Message.success({
								content: '删除订单成功'
							});
						} else {
							vm.$Message.error({
								content: '删除订单失败！'
							});
						}
						commit('setLoading', false);
					})
				}
			});
		},
		/**
		 * 提交订单
		 * @param {Object}  preload
		 */
		submitOrder({
			commit,
			dispatch
		}, preload) {
			commit('setLoading', true);
			return ajax.post('/mall/app/order/submit', preload)
				.then(res => {
					if (res && res.data && res.data.code == 0) {
						dispatch('getAll');
						dispatch('getOrderCounts');
					} else {
						vm.$Message.error({
							content: '提交订单失败！'
						});
					}
					commit('setLoading', false);
				});
		},
		/**
		 * 取消订单
		 */
		cancelOrder({
			commit,
			dispatch
		}, preload) {
			commit('setLoading', true);
			vm.$Modal.confirm({
				title: '取消订单',
				content: '确认取消订单吗?',
				onOk() {
					return ajax.post('/mall/app/order/cancel', preload).then(p => {
						if (p && p.data && p.data.code == 0) {
							dispatch('getAll');
							dispatch('getOrderCounts');
						} else {
							vm.$Message.error({
								content: '取消订单失败！'
							});
						}
						commit('setLoading', false);
					})
				}
			})
		},
		/**
		 * 确认收货
		 */
		confirmReceive({
			commit,
			dispatch
		}, preload) {
			commit('setLoading', true);
			return ajax.post('/mall/app/order/confirm', preload)
				.then(res => {
					if (res && res.data && res.data.code == 0) {
						dispatch('getAll');
						dispatch('getOrderCounts');
					} else {
						vm.$Message.error({
							content: '确认收货失败！'
						});
					}
					commit('setLoading', false);
				});
		}
	}
}
