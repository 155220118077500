import ajax from "../../lib/ajax";
export default {
  namespaced: true,
  state: {
    loading: false,
    systemUnread: 0,
    classUnread: 0,
    activityUnread: 0,
    systemTotal: null,
    classTotal: null,
    activityTotal: null,
    significantTotal: null,
    system: [],
    classList: [],
    activity: [],
    significant: [],
    page: [0, 0, 0, 0],
    size: 10,
    hasMore: [true, true, true, true],
    significantNumber: 0,
    signNumber: 0,
  },
  mutations: {
    setLoading(state, status) {
      state.loading = status;
    },
    setSystem(state, data) {
      state.systemUnread = data.unread;
      state.systemTotal = data.total;
      state.system = [...state.system, ...data.items];
    },
    setClass(state, data) {
      state.classUnread = data.unread;
      state.classTotal = data.total;
      state.classList = [...state.classList, ...data.items];
    },
    setActivity(state, data) {
      state.activityUnread = data.unread;
      state.activityTotal = data.total;
      state.activity = [...state.activity, ...data.items];
    },
    setSignificant(state, data) {
      state.significantNumber = data.unread;
      state.significantTotal = data.total;
      state.significant = [...state.significant, ...data.items];
    },
    setSize(state, data) {
      state.size = data;
    },
    setPage(state, data) {
      state.page[data.type] = data.page;
    },
    setHasMore(state, data) {
      state.hasMore[data.type] = data.hasMore;
    },
    resetByType(state, type) {
      const listNames = ["system", "classList", "activity", "significant"];
      state[listNames[type]] = [];
      state.hasMore[type] = true;
      state.page[type] = 0;
    },
    resetAll(state) {
      Object.assign(state, {
        loading: false,
        systemUnread: 0,
        classUnread: 0,
        activityUnread: 0,
        systemTotal: 0,
        classTotal: 0,
        activityTotal: 0,
        system: [],
        classList: [],
        activity: [],
        significant: [],
        page: [0, 0, 0, 0],
        size: 10,
        hasMore: [true, true, true, true],
        significantNumber: 0,
        signNumber: 0,
      });
    },
    changeStatus(state, data) {
      const listNames = ["system", "classList", "activity", "significant"];
      const unReadNames = [
        "systemUnread",
        "classUnread",
        "activityUnread",
        "significantNumber",
      ];
      const totalNames = [
        "systemTotal",
        "classTotal",
        "activityTotal",
        "significantTotal",
      ];

      if (data.isDelete) {
        state[totalNames[data.type]] = state[totalNames[data.type]] - 1;
      }

      if (data.setReadState) {
        state[unReadNames[data.type]] = state[unReadNames[data.type]] - 1;
      }

      state[listNames[data.type]] = data.items;
    },
  },
  getters: {
    totalUnread(state) {
      return state.activityUnread + state.systemUnread + state.classUnread;
    },
  },
  actions: {
    getAllNotify({ commit, state, dispatch }) {
      const repSystem = dispatch("getAllNotifyByType", 0);
      const repClass = dispatch("getAllNotifyByType", 1);
      const repActivity = dispatch("getAllNotifyByType", 2);
      const significant = dispatch("getAllNotifyByType", 3);
      return Promise.all([repSystem, repClass, repActivity, significant]);
    },
    getAllNotifyByType({ commit, state }, type) {
      if (!state.hasMore[type]) {
        return;
      }
      const commitNames = [
        "setSystem",
        "setClass",
        "setActivity",
        "setSignificant",
      ];
      const listNames = ["system", "classList", "activity", "significant"];
      commit("setPage", {
        type: type,
        page: state.page[type] + 1,
      });
      var params = {
        type: type,
        page: state.page[type],
        size: state.size,
      };
      return ajax.get("/mall/app/message/list", { params }).then((res) => {
        if (res && res.data && res.data.code == 0 && res.data.data) {
          if (params.type == 3) {
            state.signNumber = res.data.data.total;
          }
          commit(commitNames[type], res.data.data);
          if (state[listNames[type]].length == res.data.data.total) {
            commit("setHasMore", {
              type: type,
              hasMore: false,
            });
          }
        } else {
          // uni.showToast({
          // 	title: '遇到未知错误',
          // 	icon: 'none'
          // });
        }
      });
    },
    readOrDelete({ commit, dispatch, state }, preload) {
      const messageId = preload.postData.messageId;
      const listNames = ["system", "classList", "activity", "significant"];
      const listName = listNames[preload.type];

      ajax
        .post("/mall/app/message/readordelete", preload.postData)
        .then((res) => {
          if (res && res.data && res.data.code == 0) {
            let list = state[listName];
            let setReadState = false;
            let isDelete = false;
            if (preload.postData.readState && preload.postData.readState == 1) {
              for (let s of list) {
                if (s.id == messageId) {
                  s.readState = 1;
                  setReadState = true;
                  break;
                }
              }
            }

            if (preload.postData.deleted && preload.postData.deleted == 1) {
              let index = list.findIndex((item) => {
                return item.id == messageId;
              });
              if (index > -1) {
                setReadState = !list[index].readState;
                list.splice(index, 1);
              }
            }
            let commitData = {
              type: preload.type,
              isDelete: isDelete,
              setReadState: setReadState,
              items: list,
            };

            commit("changeStatus", commitData);
          }
        });
    },
  },
};
