import Util from "@/lib/util.js";
import ajax from '../../lib/ajax'

export default {
	namespaced: true,
	state: {
		id: null,
		category:[],
		detail:{
			
		},
		exhibition: {},
		exhibitionGoods: [],
		honor: [],
		qualification: [],
		category: [],
		showEnterprise: false,
		alertMessage: '',
        recommendGoods:[],
        topGoods:[]
	},
	mutations: {
		setId(state,id) {
			state.id = id;
		},
		setData(state,data) {
			console.log("setData: ",data);
			state.exhibition = data.detail;
			state.exhibitionGoods = data.detail.exhibitionGoods;
			state.honor = data.detail.honor;
			state.qualification = data.detail.qualification;
			state.category = data.category;

            if (data && data.exhibitionGoods && data.exhibitionGoods.length > 4) {
                state.recommendGoods = data.exhibitionGoods.slice(4);
                state.topGoods = data.exhibitionGoods.slice(0, 4);
            } else {
                state.recommendGoods = data.exhibitionGoods;
                state.topGoods = data.exhibitionGoods;
            }
		},
		setShowEnterprise(state,data){
			state.showEnterprise = data.flag;
			state.alertMessage = data.alertMessage;
		}
	},
	actions: {
		getExhibitionDetail({
			commit,
			dispatch,
			state
		}) {
			return ajax.get('/mall/app/supplier/detail', {
				params: {
					id: state.id
				}
			}).then(res => {
				if (res && res.data.data == null && res.data.code == 0) {
					commit('setShowEnterprise',{flag: false,alertMessage: '该公司已下架，是否删除收藏'});
				}
				if (res && res.data && res.data.code == 0) {
					if (res.data.data) {
						commit("setData",res.data.data);
						commit('setShowEnterprise',{flag: true,alertMessage: '加载成功'});
					}
				} else if (res.data.code == 502) {
					commit('setShowEnterprise',{flag: false,alertMessage: '该公司已被管理员删除'});
					const a = {
						type: 2,
						valueId: self.id
					}
					ajax.post('/mall/app/collect/delete', a);
				}
			})
		}
	}
}