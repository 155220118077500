import Util from "@/lib/util.js";
import ajax from "../../lib/ajax";
export default {
  namespaced: true,
  state: {
    userInfo: {
      userId: null,
      username: "",
      nickName: "",
      avatarUrl: "",
      birthday: null,
      gender: 0,
      phone: "",
      userLevel: 0,
      userLevelDesc: "",
      userType: null,
      sand: null,
      integral: null,
      mail: "",
      status: 0,
      registerDate: "",
      occupationNumber: "",
      qualificationNumber: "",
      medicalCertificate: [],
      workplace: "",
      name: "",
      password: 0,
      shareUserId: 0,
      supplierId: null,
      businessCard: 0,
    },
    hasLogin: false,
    recruitmentImages: true,
  },
  mutations: {
    setRecruitmentImages(state, bool) {
      state.recruitmentImages = bool;
    },
    setUser(state, user) {
      Object.assign(state.userInfo, user);
    },
    setHasLogin(state, isLogin) {
      state.hasLogin = isLogin;
    },
    setPhoneNumber(state, number) {
      state.userInfo.phone = number;
    },
    setNickName(state, NickName) {
      state.userInfo.nickName = NickName;
    },
    setAvatar(state, url) {
      state.userInfo.avatarUrl = url;
    },
    login(state, { userInfo, token }) {
      Util.abp.auth.setToken(token);
      state.userInfo = userInfo;
      state.hasLogin = true;
    },
    logout(state) {
      state.hasLogin = false;
      state.userInfo = {
        userId: null,
        username: "",
        nickName: "",
        avatarUrl: "",
        birthday: null,
        gender: 0,
        phone: "",
        userLevel: 0,
        userLevelDesc: "",
        userType: null, //1: 医生，3: 护士
        sand: null,
        integral: null,
        mail: "",
        status: 0,
        registerDate: "",
        occupationNumber: "",
        qualificationNumber: "",
        medicalCertificate: [],
        workplace: "",
        name: "",
        password: 0,
        shareUserId: 0,
        supplierId: null,
        partnerType: 0,
        partnerName: "",
      };
      Util.abp.auth.clearToken();
    },
  },
  actions: {
    getUserInfo(context) {
      if (context.state.hasLogin) {
        return ajax.get("/mall/app/user/detail").then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          context.commit("setUser", res.data.data);
          if (res.data.data.partnerType == 0) {
            context.commit("setRecruitmentImages", true);
          } else {
            context.commit("setRecruitmentImages", false);
          }
        });
      }
      return Promise.reject();
    },
  },
  getters: {
    isComplete(state) {
      if (state.hasLogin) {
        if (state.userInfo.userType == 3) {
          return (
            !!state.userInfo.occupationNumber &&
            !!state.userInfo.medicalCertificate.length >= 1
          );
        }
        if (state.userInfo.userType == 1) {
          return (
            !!state.userInfo.occupationNumber &&
            !!state.userInfo.qualificationNumber &&
            state.userInfo.medicalCertificate.length >= 2
          );
        }
      }
      return false;
    },
    userStatus(state) {
      if (!state.hasLogin) {
        return {
          status: -1,
          text: "您还没有登录！",
        };
      } else {
        if (
          state.userInfo.userType == 0 ||
          state.userInfo.userType == 1 ||
          state.userInfo.userType == 3
        ) {
          let strStatus = "";
          switch (state.userInfo.status) {
            case 0:
              strStatus = "为了获得更好的体验，请完善您的个人资料。";
              break;
            case 1:
              strStatus = "抱歉，您的账号已被禁用，请联系管理员。";
              break;
            case 2:
              strStatus = "注销。";
              break;
            case 3:
              strStatus = "恭喜您，您的账号已通过审核。";
              break;
            case 4:
              strStatus = "抱歉！您的资料审核未通过，请重新上传。";
              break;
            case 5:
              strStatus = "您的账号正在审核中，请耐心等待。";
              break;
          }
          return {
            status: state.userInfo.status,
            text: strStatus,
          };
        } else {
          return {
            status: -2,
            text: "非法用户",
          };
        }
      }
    },
  },
};
