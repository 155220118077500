import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ViewUI from "./components/view-design";
import Util from "@/lib/util.js";
import "./my-theme/index.less";
import "./styles/base.scss";
import ajax from "./lib/ajax";
import myFilter from "./lib/my-vue-filter.js";
require("promise.prototype.finally").shim();
import Storage from "vue-ls";
import tinymce from "tinymce";
import VueTinymce from "@packy-tang/vue-tinymce";
import hevueImgPreview from "hevue-img-preview";

// 做一下兼容
Promise.allSettled =
  Promise.allSettled ||
  ((promises) =>
    Promise.all(
      promises.map((p) =>
        p
          .then((value) => ({
            status: "fulfilled",
            value,
          }))
          .catch((reason) => ({
            status: "rejected",
            reason,
          }))
      )
    ));

Vue.use(hevueImgPreview);

let options = {
  namespace: "vuejs__", // key键前缀
  name: "ls", // 命名Vue变量.[ls]或this.[$ls],
  storage: "local", // 存储名称: session, local, memory
};

Vue.use(Storage, options);

Vue.use(ViewUI);
// 设置Message距离顶端的距离
Vue.prototype.$Message.config({
  top: 124,
});

Vue.config.productionTip = false;

Vue.prototype.$http = ajax;

Vue.use(myFilter);

// 将全局tinymce对象指向给Vue作用域下
Vue.prototype.$tinymce = tinymce;
Vue.use(VueTinymce);

// 读取登录状态
const token = Util.abp.auth.getToken();
if (token) {
  store.commit("session/setHasLogin", true);
} else {
  store.commit("session/setHasLogin", false);
}

store
  .dispatch({
    type: "session/getUserInfo",
  })
  .finally((_) => {
    new Vue({
      router,
      store,
      render: (h) => h(App),
      async mounted() {
        if (token) {
          this.$store.dispatch("cart/detail");
          this.$store.dispatch("order/getOrderCounts");
        }
        // await this.$store.dispatch({
        // 	type: 'session/getUserInfo'
        // })
      },
    }).$mount("#app");
  });
