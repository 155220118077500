<template>
	<div id="app" class="layout">
		<router-view />
        <BackTop :height='300'></BackTop>
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {
		},
        data(){
            return{

            }
        },
        watch:{
            $route:{
                handler(val,ordval){
                    if(val){
                        window.scrollTo(0,0);
                    }
					// 刷新消息数量
					if (this.$store.state.session.hasLogin) {
						this.$store.commit('notify/resetAll');
						this.$store.dispatch('notify/getAllNotify');
					}
                }
            }
        }
        
	}
</script>

<style lang="scss">
	
</style>
