import Vue from 'vue'
import Vuex from 'vuex'
import session from './modules/session.js'
import cart from './modules/cart.js'
import order from './modules/order.js'
import notify from './modules/notify.js'
import enterprise from './modules/enterprise.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    session,
		cart,
		order,
		notify,
		enterprise
  }
})
